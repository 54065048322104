<script lang="ts" setup>
const { ausserbetriebsetzung } = await usePrice()

const checkListItems = [
  'Funktioniert mit allen Fahrzeugtypen: Auto, Motorrad, Anhänger etc.',
  'Automatisierte Bearbeitung: Ergebnis in Minuten',
  'Digitale Abmeldebescheinigung als PDF (wird auch per E-Mail zugesendet)',
  'Inklusive aller Gebühren (Kfz-Zulassungsstelle & Kraftfahrt-Bundesamt)',
  'Zentrales Fahrzeugregister: Berichtigung der Erfassungsunterlagen'
]
</script>

<template lang="pug">
.price-card.border.border-gray-200.rounded-xl.bg-white.py-6.px-3.text-center(class="sm:px-6")
  .flex.items-center.justify-between
    p.text-xl.font-semibold Kfz-Abmeldung
    p.font-light.text-2xl {{ ausserbetriebsetzung.asString }}

  hr.my-6

  CheckList(:items="checkListItems").mt-4.font-light.text-left

  hr.my-6

  .flex.justify-start
    nuxt-link(to="/ausserbetriebsetzung/vorbereitung")
      UButton(size="sm") Jetzt Fahrzeug abmelden

</template>

<style lang="sass">
.price-card
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)


</style>
